<template>
	<div class="sld_chat_right">
		<div class="right_tab">
			<div :class="{ tab1: true, on: 1 == tabIndex }" @click="changeTab(1)">{{ L['用户订单'] }}</div>
			<div :class="{ tab2: true, on: 2 == tabIndex }" @click="changeTab(2)">{{ L['用户足迹'] }}</div>
			<div :class="{ tab3: true, on: 3 == tabIndex }" @click="changeTab(3)" v-if="identity == 'seller'">
				{{ L['店铺商品'] }}
			</div>
		</div>

		<div class="right_list_con" v-if="1 == tabIndex" style="height: 0">
			<el-scrollbar ref="orderbar">
				<!-- 订单列表start -->
				<div class="orderOwn" v-infinite-scroll="load">
					<div class="order_item" v-for="(item, orderIndex) in orderList.list" :key="orderIndex">
						<div class="order_title">
							<div class="order_title_info">
								<p>{{ L['订单号'] }}：{{ item.orderSn }}</p>
								<div class="order_state">{{ item.orderStateValue }}</div>
							</div>
							<p class="update_time">{{ item.createTime }}</p>
							<!-- <div class="order_state">{{ item.orderStateValue }}</div> -->
						</div>
						<div>
							<div
								class="goods_model order_type"
								:class="{ sld_hide: item.isFold, sld_show: !item.isFold }"
								v-for="(goodsItem, goodsIndex) in item.orderProductList"
								:key="goodsIndex"
								@click="toDetail(item, 'order')"
							>
								<div class="goods_m_img">
									<img :src="goodsItem.productImage" alt="" />
								</div>
								<div class="goods_info">
									<div class="goods_info_title">
										{{ goodsItem.goodsName }}
									</div>
									<div class="goods_info_bottom">
										<span>{{ L['￥'] }}{{ goodsItem.productShowPrice.toFixed(2) }}</span>
										<span
											@click.stop="
												sendLink(
													{
														orderSn: item.orderSn,
														createTime: item.createTime,
														orderStateValue: item.orderStateValue,
														goodsItem,
													},
													'order'
												)
											"
											>{{ L['发送链接'] }}</span
										>
									</div>
								</div>
							</div>
						</div>

						<div class="openMore" @click="openMore(item)" v-if="item.orderProductList.length > 3">
							<span>{{ item.isFold ? '展开全部' : '收起全部' }}</span>
							<i :class="item.isFold ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
						</div>
					</div>
					<loadingState v-if="loadState.orderState == 'first_loading' || orderList.list.length > 0" :state="loadState.orderState" />
					<div class="empty_data" v-if="!orderList.list.length">
						<!-- <img src="@/assets/goods/empty_data.png" alt="" /> -->
						<p>{{ L['暂无订单'] }}</p>
					</div>
				</div>
				<!-- 订单列表start -->
			</el-scrollbar>
		</div>

		<div class="right_list_con" v-if="2 == tabIndex" style="height: 0">
			<el-scrollbar ref="footbar">
				<!-- 我的足迹start -->
				<div class="foot_print" v-infinite-scroll="load">
					<div class="goods_model" v-for="(footItem, footIndex) in footPrintList.list" :key="footIndex" @click="toDetail(footItem, 'goods')">
						<div class="goods_m_img">
							<img :src="footItem.goodsImage" alt="" />
						</div>
						<div class="goods_info">
							<div class="goods_info_title">
								{{ footItem.goodsName }}
							</div>
							<div class="goods_info_bottom">
								<span>{{ L['￥'] }}{{ footItem.productPrice.toFixed(2) }}</span>
								<span @click.stop="sendLink(footItem, 'foot')">{{ L['发送链接'] }} </span>
							</div>
						</div>
					</div>
					<loadingState v-if="loadState.footState == 'first_loading' || footPrintList.list.length > 0" :state="loadState.footState" />
					<div class="empty_data" v-if="!footPrintList.list.length">
						<!-- <img src="@/assets/goods/empty_data.png" alt="" /> -->
						<p>{{ L['暂无足迹'] }}</p>
					</div>
				</div>
				<!-- 我的足迹end -->
			</el-scrollbar>
		</div>

		<div class="right_list_con" v-if="3 == tabIndex" style="height: 0">
			<el-scrollbar ref="recombar">
				<!-- 店铺推荐start -->
				<div class="store_recom" v-infinite-scroll="load">
					<div class="goods_model" v-for="(recomItem, recomIndex) in recomList.list" :key="recomIndex" @click="toDetail(recomItem, 'goods')">
						<div class="goods_m_img">
							<img :src="recomItem.mainImage" alt="" />
						</div>
						<div class="goods_info">
							<div class="goods_info_title">
								{{ recomItem.goodsName }}
							</div>
							<div class="goods_info_bottom">
								<span>{{ L['￥'] }}{{ recomItem.goodsPrice.toFixed(2) }}</span>
								<span @click.stop="sendLink(recomItem, 'recom')">{{ L['发送链接'] }} </span>
							</div>
						</div>
					</div>
					<loadingState v-if="loadState.recomState == 'first_loading' || recomList.list.length > 0" :state="loadState.recomState" />
					<div class="empty_data" v-if="!recomList.list.length">
						<!-- <img src="@/assets/goods/empty_data.png" alt="" /> -->
						<p>{{ L['暂无商品'] }}</p>
					</div>
				</div>
				<!-- 店铺推荐end -->
			</el-scrollbar>
		</div>
	</div>
</template>

<script>
import { ref, onMounted, reactive, getCurrentInstance, watch } from 'vue';
import loadingState from '@/components/loadingState';
import { pcUrl, sourceUrl } from '@/utils/config.js';
export default {
	name: 'chatRightList',
	components: {
		loadingState,
	},
	setup(props, { emit }) {
		const tabIndex = ref(1);
		const changeTab = (index) => {
			tabIndex.value = index;
		};
		const { proxy } = getCurrentInstance();
		const orderList = reactive({ list: [] });
		const footPrintList = reactive({ list: [] });
		const recomList = reactive({ list: [] });
		const current = reactive({
			order: 1,
			foot: 1,
			recom: 1,
		});
		const hasMore = reactive({
			orderMore: true,
			footMore: true,
			recomMore: true,
		});
		const loadState = reactive({
			orderState: '',
			footState: '',
			recomState: '',
		});
		const clientHeight = ref(0);
		const L = proxy.$getCurLanguage();
		const identity = localStorage.getItem('identity');
		const getOrderList = () => {
			proxy.$get(`v3/business/${identity}/orderInfo/userOrders`, { current: current.order, memberId: CurmemberId.value }).then((res) => {
				if (res.state == 200) {
					if (current.order == 1) {
						orderList.list = res.data.list;
					} else {
						orderList.list = orderList.list.concat(res.data.list);
					}
					hasMore.orderMore = checkPaginationHasMore(res.data.pagination);
					if (hasMore.orderMore) {
						current.order++;
						loadState.orderState = 'allow_loading_more';
					} else {
						loadState.orderState = 'no_more_data';
					}
					orderList.list.map((item) => {
						item.isFold = true;
					});
				}
			});
		};

		const getFootList = () => {
			proxy.$get(`v3/member/${identity}/productLook/userFootprint`, { current: current.foot, memberId: CurmemberId.value }).then((res) => {
				if (res.state == 200) {
					if (current.foot == 1) {
						footPrintList.list = res.data.list;
					} else {
						footPrintList.list = footPrintList.list.concat(res.data.list);
					}
					hasMore.footMore = checkPaginationHasMore(res.data.pagination);
					if (hasMore.footMore) {
						current.foot++;
						loadState.footState = 'allow_loading_more';
					} else {
						loadState.footState = 'no_more_data';
					}
				}
			});
		};

		const getRecomList = () => {
			proxy.$get(`v3/goods/${identity}/goods/list`, { current: current.recom }).then((res) => {
				if (res.state == 200) {
					if (current.recom == 1) {
						recomList.list = res.data.list;
					} else {
						recomList.list = recomList.list.concat(res.data.list);
					}
					hasMore.recomMore = checkPaginationHasMore(res.data.pagination);
					if (hasMore.recomMore) {
						current.recom++;
						loadState.recomState = 'allow_loading_more';
					} else {
						loadState.recomState = 'no_more_data';
					}
				}
			});
		};

		const CurmemberId = ref(0);
		const getSwitch = (memberId) => {
			CurmemberId.value = memberId;
			current.order = 1;
			current.foot = 1;
			if (memberId === '') {
				orderList.list = [];
				footPrintList.list = [];
			} else {
				getFootList();
				getOrderList();
			}
		};

		const sendLink = (obj, type) => {
			emit('getObj', obj, type);
		};

		const openMore = (item) => {
			item.isFold = !item.isFold;
		};

		const load = () => {
			if (tabIndex.value == 1 && hasMore.orderMore) {
				getOrderList();
			} else if (tabIndex.value == 2 && hasMore.footMore) {
				getFootList();
			} else if (tabIndex.value == 3 && hasMore.recomMore) {
				getRecomList();
			}
		};

		watch(
			() => CurmemberId.value,
			(nv, ov) => {
				if (nv != ov) {
					if (proxy.$refs.orderbar) {
						// proxy.$refs.orderbar.wrap.scrollTop = 0;
					}

					if (proxy.$refs.footbar) {
						// proxy.$refs.footbar.wrap.scrollTop = 0;
					}
				}
			}
		);

		const checkPaginationHasMore = ({ current, pageSize, total }) => {
			return current * pageSize < total * 1;
		};

		const toDetail = (item, type) => {
			switch (type) {
				case 'order': {
					let url;
					// TODO【MD】admin
					if (identity == 'admin') {
						url = `${sourceUrl}manage_order/order_lists_to_detail?orderSn=${item.orderSn}`;
					} else {
						url = `${sourceUrl}order/order_lists_to_detail?orderSn=${item.orderSn}`;
					}

					window.open(url, '_blank');
					break;
				}

				case 'goods': {
					let url;
					if (pcUrl) {
						url = `${pcUrl}pages/product/detail??productId=${item.productId}`;
					} else {
						// TODO【MD】admin
						if (identity == 'admin') {
							url = `${sourceUrl}manage_product/goods_detail?id=${item.goodsId}`;
						} else {
							url = `${sourceUrl}goods/goods_detail?id=${item.goodsId}`;
						}
					}

					window.open(url, '_blank');
					break;
				}
			}
		};

		onMounted(() => {
			clientHeight.value = document.body.clientHeight || document.documentElement.clientHeight;
			getRecomList();
		});

		return {
			changeTab,
			tabIndex,
			orderList,
			footPrintList,
			recomList,
			getOrderList,
			getFootList,
			getRecomList,
			sendLink,
			load,
			loadState,
			getSwitch,
			CurmemberId,
			clientHeight,
			L,
			identity,
			toDetail,
			openMore,
		};
	},
};
</script>

<style lang="scss" scoped>
.sld_chat_right {
	width: 287px;
	margin-left: 24px;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;

	.right_tab {
		display: flex;
		width: 100%;
		border-bottom: 1px solid #b8b8b8;
		z-index: 2;
		div[class^='tab'] {
			transform: translateY(1px);
			font-size: 16px;
			line-height: 20px;
			color: #767676;
			background: #e7e7e7;
			height: 43px;
			line-height: 43px;
			cursor: pointer;
			text-align: center;
			font-family: PingFang SC;
			flex: 1;
			border: 1px solid #b8b8b8;
			border-radius: 10px 10px 0 0;
			&.on {
				color: #000;
				background: #fff;
				border-bottom: 0;
			}
			&:not(:last-of-type) {
				margin-right: 12px;
			}
		}
	}

	.right_list_con {
		background-color: #fff;
		flex: 1;
		padding-bottom: 40px;
		border-radius: 0 0 10px 10px;
		border: 1px solid #b8b8b8;
		border-top: none;
		padding-top: 18px;
		box-shadow: 2px 6px 12px 0px rgba(143, 141, 143, 0.3);
		> div:first-child {
			border-top: 1px solid #eeeeee;
		}
		.orderOwn {
			.order_item {
				margin: 20px 15px 0;
				padding-bottom: 15px;
				border-bottom: 1px solid #eeeeee;
				.sld_hide:nth-child(n + 4) {
					display: none;
				}

				.sld_show {
					display: flex;
				}

				.order_title {
					// display: flex;
					// justify-content: space-between;
					// align-items: center;
					padding-bottom: 10px;
					// border-bottom: 1px solid #f8f8f8;

					.order_title_info {
						display: flex;
						justify-content: space-between;
						margin-bottom: 8px;

						p:first-child {
							font-size: 12px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #666666;

							//TODO
							font-family: Source Han Sans CN-Regular, Source Han Sans CN;
							color: #17171a;
						}

						p:nth-child(2) {
							font-size: 12px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #999999;

							font-family: Source Han Sans CN-Regular, Source Han Sans CN;
							font-weight: 400;
							color: #a7a6ae;
						}
					}

					.update_time {
						font-size: 12px;
						font-weight: 400;
						color: #999999;
						font-weight: 400;
						color: $colorDark2;
					}

					.order_state {
						color: $colorDark2 !important;

						font-size: 12px;
						font-family: Source Han Sans CN-Regular, Source Han Sans CN;
						font-weight: 400;
						color: #a7a6ae;
					}
				}

				.order_type {
					// margin-top: 17px;
					cursor: pointer;
					margin-top: 0;
				}

				.openMore {
					margin-top: 20px;
					text-align: center;
					cursor: pointer;

					span {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
					}

					i {
						margin-left: 10px;
						font-size: 9px;
					}

					&:hover {
						span,
						i {
							color: $colorMain;
						}
					}
				}
			}
		}

		.foot_print,
		.store_recom {
			padding: 0px 20px;
			.goods_model {
				cursor: pointer;
				border-bottom: 1px solid #eeeeee;
				margin-top: 15px;
				padding-bottom: 12px;
			}
		}
	}

	.goods_model {
		display: flex;
		margin-top: 22px;

		.goods_m_img {
			// width: 69px;
			// height: 66px;
			width: 56px;
			height: 56px;
			// border-radius: 6px;
			background: #f5f5f5;
			display: flex;
			justify-content: center;
			overflow: hidden;

			img {
				/* width: 69px; */
				// height: 66px;
				height: 56px;
				/* border-radius: 6px; */
			}
		}

		.goods_info {
			margin-left: 10px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 81%;

			.goods_info_title {
				font-size: 12px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				line-height: 18px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;

				font-size: 14px;
				font-family: Source Han Sans CN-Regular, Source Han Sans CN;
				font-weight: 400;
				color: #17171a;
				line-height: 20px;
			}

			.goods_info_bottom {
				display: flex;
				justify-content: space-between;
				align-items: center;

				span:first-child {
					font-size: 14px;
					font-family: Source Han Sans CN-Medium, Source Han Sans CN;
					font-weight: 500;
					color: #17171a;
					line-height: 24px;
				}

				span:nth-child(2) {
					font-size: 12px;
					cursor: pointer;
					font-weight: 500;
					color: $colorDark;
				}
			}
		}
	}
}

.empty_data {
	height: 300px;
	padding-top: 100px;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 80px;
	}

	p {
		margin-left: 10px;
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #d5d5d5;
	}
}
</style>
