<template>
	<div class="sld_chat_page">
		<chatLeftList :showTab="false" is_chat_room @switchMember="switchMember" ref="left" :connectBaseData="connectBaseData" />
		<div class="chat_zone">
			<chatWindow ref="chatWindow" is_chat_room @closeChatMember="closeChatMember"></chatWindow>
		</div>
		<chatRightList @getObj="getObj" ref="right" v-if="room_info.memberId !== defaultAdminId" />
	</div>
</template>
<script>
// import "element-plus/lib/index.full.js";
import 'element-plus/dist/index.full.js';
import chatWindow from '@/components/chatWindow';
import { ref, reactive, getCurrentInstance, watchEffect } from 'vue';
import { defaultAdminId } from '@/utils/config';
import chatLeftList from './chatLeftList';
import chatRightList from './chatRightList';
import { pcUrl, storeUrl, sourceUrl } from '@/utils/config';
export default {
	name: 'chatPage',
	components: {
		chatWindow,
		chatLeftList,
		chatRightList,
	},
	beforeCreate() {},

	setup(props, { emit }) {
		// TODO【MD】seller

		const connectBaseData = {
			storeId: localStorage.identity == 'seller' ? localStorage.storeId : 0,
			userId: localStorage.identity == 'seller' ? +localStorage.vendorId : +localStorage.adminId,
			role: 2,
		};

		// const connectBaseData = { storeId: 4, userId: 4, role: 2 };
		const { proxy } = getCurrentInstance();
		const L = proxy.$getCurLanguage();
		const identity = localStorage.getItem('identity');
		//获取现在的会员的信息
		const curChatMemInfo = (data) => {};
		let room_info = ref({});
		//切换会员需要重新获取聊天列表以及用户的信息
		const switchMember = (data) => {
			let window_instance = proxy.$refs.chatWindow;
			room_info.value = { ...data };
			let { memberId, storeId, vendorId, memberName } = data;
			window_instance.setId(data);
			emit('getCurMemberId', data);
			proxy.$refs.right && proxy.$refs.right.getSwitch(memberId);
		};
		const closeChatMember = (memberId) => {
			console.log(memberId);
			proxy.$refs.left.closeChatMember({ memberId });
		};
		//获取订单，足迹，推荐商品，并发送
		const getObj = (data, type) => {
			let msgData = {};
			msgData.memberId = room_info.value.memberId;
			msgData.vendorId = room_info.value.vendorId;
			if (type == 'foot') {
				msgData.msgType = 3;
				msgData.msg = {
					productId: data.productId,
					goodsImage: data.goodsImage,
					goodsName: data.goodsName,
					goodsPrice: data.productPrice,
				};
			} else if (type == 'recom') {
				msgData.msgType = 3;
				msgData.msg = {
					productId: data.productId,
					goodsImage: data.mainImage,
					goodsName: data.goodsName,
					goodsPrice: data.goodsPrice,
				};
			} else if (type === 'order') {
				msgData.msgType = 4;
				msgData.msg = {
					orderSn: data.orderSn,
					createTime: data.createTime,
					orderStateValue: data.orderStateValue,
					productId: data.goodsItem.productId,
					goodsImage: data.goodsItem.productImage,
					goodsName: data.goodsItem.goodsName,
					goodsPrice: data.goodsItem.productShowPrice,
				};
			}
			proxy.$socket.emit('send_msg', { ...msgData, ...connectBaseData });
			//scrolltoNewMsg();
		};

		watchEffect(() => {});
		// 消息提示音
		const play = () => {
			console.log('playPage');
			let audioElement = document.createElement('audio');
			let voice = require('@/assets/media/msg.mp3');
			audioElement.setAttribute('src', voice);
			audioElement.setAttribute('autoplay', 'autoplay');
		};

		return {
			switchMember,
			curChatMemInfo,
			getObj,
			connectBaseData,
			room_info,
			closeChatMember,
			defaultAdminId,
		};
	},
};
</script>
<style lang="scss" scoped>
.sld_chat_page {
	flex-shrink: 0;
	width: 100%;
	height: 810px;
	display: flex;
	flex-shrink: 0;

	.chat_zone {
		flex: 1;
		height: 100%;
	}
}
</style>
